import _ from 'lodash'

import Menu_parcelles from './menu_parcelles.vue'
import Menu_parcelle_listes from './menu_parcelle_listes.vue'
import Menu_recherches from './menu_recherches.vue'
import Menu_sites from './menu_sites.vue'
import Menu_surfaces from './menu_surfaces.vue'
import FiltreHeader from '../filtres/_filtre_header.vue'

export default {
	data() {
		return {
			menus:['recherches', 'parcelle_listes', 'surfaces', 'sites']
		}
	},
	created(){
		let self = this

		//AS--> Défini le sous-menu à activer par défaut quand il n'est pas encore sélectionné
		if(!this.$store.state.ui.allfavoris_subcontent){

			let subcontent = '';

			this.menus.forEach(function(menu){
				switch(menu){
					case 'recherches':
					case 'sites':
						if(!subcontent && self.$store.getters.canFavoris) subcontent = 'recherches'
						break;
					case 'parcelles':
						if(!subcontent && self.$store.getters.canParcelleFavoris) subcontent = 'parcelles'
						break;
					case 'surfaces':
						if(!subcontent && self.$store.getters.canSurfaces) subcontent = 'surfaces'
						break;
					case 'parcelle_listes':
						if(!subcontent && self.$store.getters.canParcelleListes) subcontent = 'parcelle_listes'
						break;
				}
			})

			if(subcontent) this.$store.commit('UI_SET_ALLFAVORIS_SUBCONTENT', subcontent);
		}
	},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FAVORIS_MENU', this.$store.state.ui.show_favoris_menus ? false : true);
		}
	},
	computed:{
		
	},
	components: {
		Menu_parcelles,
		Menu_parcelle_listes,
		Menu_recherches,
		Menu_sites,
		Menu_surfaces,
		FiltreHeader
	}
}