import Chip from '../../common/ui/chip.vue'
import MenuSousMenu from '../../common/ui/menu_sous-menu.vue'
import DatesMixin from '../../common/mixins/Dates'

export default {
	mixins: [DatesMixin],
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		deleteParcelleListe(parcelle_liste){
			let self = this;
			this.$dialog.confirm(this.$t('parcelle_listes_favoris_supprimer_confirmation'), {loader: true})
			.then(dialog => {
				self.$store.dispatch('deleteParcelleListe', parcelle_liste);
				setTimeout(function(){
					dialog.close();
				}, 200);
			});
		}
	},
	computed:{
		parcelle_listes(){
			return this.$store.state.parcelle.parcelles_lists.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
		},
		settings(){
			return {
				menu: {
					picto: 'Action',
					label: false,
					arrow: false,
					bg_color: '#F4F6F9',
					color: null
				},
				sousmenu: {
					class: '',
					list: [
						{
							picto: 'Trash2',
							label: this.$t('parcelle_listes_favoris_supprimer'),
							action: this.deleteParcelleListe,
							viewBox: '0 0 24 24',
							width: 18,
							height: 18
						}
					]
				}
			}
		}
	},
	components:{
		Chip,
		MenuSousMenu
	}
}